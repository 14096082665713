import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";
// import { configureChains, createConfig, WagmiConfig } from "wagmi";
// import { alchemyProvider } from "wagmi/providers/alchemy";
// import { publicProvider } from "wagmi/providers/public";
import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';

import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import '@rainbow-me/rainbowkit/styles.css';
import { Toaster } from "react-hot-toast";
import {
  getDefaultConfig,
} from '@rainbow-me/rainbowkit';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  bscTestnet,
  bsc,
} from 'wagmi/chains';


const root = ReactDOM.createRoot(document.getElementById("root"));


export const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '5a610e308d951bb057bc3f70f303e573',
  chains: [bsc, polygon, optimism, arbitrum, base],
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();


root.render(
  <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
      <RainbowKitProvider modalSize="compact"  >
      <BrowserRouter>
        <App />
        <Toaster/>
      </BrowserRouter>
    </RainbowKitProvider>
    </QueryClientProvider>
    </WagmiProvider>
);