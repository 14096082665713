import React, { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form default submission behavior
    const { name, phone, subject } = formData;
    const mailtoLink = `mailto:contact@ameyaanxt.network?subject=Message from ${name}&body=Name: ${name}%0APhone: ${phone}%0A%0A${subject}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="row col-lg-12 p con" style={{ marginTop: "150px" }}>
      <div className="col-lg-6">
        <img src="/image/contact.jpg" alt="" srcset="" className="img-fluid" style={{borderRadius:'10px'}}/>
      </div>
      <div className="col-lg-4 contactUs p-3">
        <h1 className="col-lg-12">Contact Us</h1>

        <form onSubmit={handleSubmit}>
          <div className="col-lg-12">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-12">
            <label htmlFor="phone">Phone</label>
            <input
              type="number"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-12">
            <label htmlFor="subject">Subject</label>
            <textarea
              name="subject"
              placeholder="Subject"
              rows={3}
              value={formData.subject}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="col-lg-12">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
