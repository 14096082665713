import React from "react";
import Marquee from "react-fast-marquee";

function Partner() {
  return (
    <div className="row col-lg-12 partner">
      <Marquee
        className="partner"
        style={{ gap: "10px", display: "flex", delay: "0" }}
      >
        <img
          src="https://sale.starchain.dev/assets/images/brand-logo/coinmarketcap.svg"
          alt=""
          srcset=""
        />
        <img
          src="https://sale.starchain.dev/assets/images/brand-logo/cointelegraph.svg"
          alt=""
        />
        <img
          src="	https://sale.starchain.dev/assets/images/brand-logo/decrypt.svg"
          alt=""
          srcset=""
        />
        <img
          src="	https://sale.starchain.dev/assets/images/brand-logo/insidebitcoins.svg"
          alt=""
        />
        <img
          src="https://sale.starchain.dev/assets/images/brand-logo/cointelegraph.svg"
          alt=""
        />

      </Marquee>
    </div>
  );
}

export default Partner;
