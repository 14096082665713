import React from "react";

function Tokenomics() {
  return (
    <div className="row col-lg-12 mt-5" style={{ background: "#000100" }}>
      <div
        className="col-lg-12 g-title text-center"
        style={{
          marginTop: "70px",
          marginBottom: "100px",
        }}
      >
        Tokenomics
      </div>

      <div
        className="row col-lg-12 p"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="col-lg-5 tokenomics">
          <div>
            <span className="left-s"> TOKEN :</span> <span>Ameyaa NXT</span>
          </div>
          <div>
            €<span className="left-s">SYMBOL :</span> <span>Anxt</span>
          </div>
          <div>
            <span className="left-s">TYPE :</span> <span>Utility token</span>
          </div>
          <div>
            <span className="left-s">BLOCKCHAIN :</span> <span>BINANCE</span>
          </div>
          <div>
            <span className="left-s">TOKEN SUPPLY :</span>{" "}
            <span>2,000,000,000</span>
          </div>
        </div>
        <div className="col-lg-7 p-5 ">
          <video
            className="img-fluid video"
            data-v-27edef16=""
            data-test="video-content"
            preload="auto"
            src="./image/earth.mp4"
            playsInline
            loop
            muted
            autoPlay
            draggable="false"
          ></video>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
