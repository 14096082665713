import { ConnectButton } from "@rainbow-me/rainbowkit";
import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import {
  getCurrentPrice,
  getowner,
  getUserBalance,
  stakeBNB,
  tokenAllowance,
  tokenApprove,
} from "./web3";
import { toast } from "react-hot-toast";
import Particle from "./Particle";
import { useNavigate } from "react-router-dom";
import { getToken } from "./api";

function Presale() {
  const targetDate = new Date("2025-01-31T23:59:59").getTime();
  const { address } = useAccount();
  const [userBalance, setUserBalance] = useState(0);
  const [refAddress, setRefAddress] = useState();
  const [amt, setAmt] = useState(0);
  const [own, setOwn] = useState();
  const [currentPrice, setCurrentPrice] = useState(0);
  const [pay, setPay] = useState(0);
  const navigate = useNavigate();
  const [totalMht, setTotalMht] = useState();
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
      document.documentElement.style.setProperty("--x", clientX);
      document.documentElement.style.setProperty("--y", clientY);
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const tokenApp = async (amt) => {
    try {
      const allowanceCheck = await tokenAllowance(address);
      console.log(
        amt,
        currentPrice,
        "in tokenApp",
        amt * currentPrice + 1,
        "::::"
      );
      const res = tokenApprove(amt * currentPrice + 1);
      await toast.promise(res, {
        loading: "Approving token",
        success: "Approve Successfull",
        error: "Transaction failed",
      });
      return res;
    } catch (error) {
      return false;
    }
  };

  const stake = async () => {
    try {
      if (!address) {
        return toast.error("Please Connect Your wallet");
      }
      const res = await tokenApp(amt);
      if (res) {
        const stake = stakeBNB(amt, refAddress ? refAddress : own);
        await toast.promise(stake, {
          loading: "Transaction pending",
          success: "Transaction successfull",
          error: "Transaction failed",
        });
      }
      if (res) {
        navigate("/token");
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      // toast.error(error.message);
    }
  };

  useEffect(() => {
    getUserBalance(address)
      .then((res) => {
        setUserBalance(res);
      })
      .catch((e) => {
        console.log(e);
      });

    getowner()
      .then((res) => {
        setOwn(res);
      })
      .catch((e) => {
        console.log(e);
      });

    getCurrentPrice()
      .then((res) => {
        setCurrentPrice(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [address]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getToken();
        if (res.status === 200) {
          console.log(res?.data?.data);
          const totalAmount = res?.data?.data?.reduce((acc, item) => {
            return (acc + (Number(item.amount) || 0)) / 1e18; // Add amount or 0 if undefined
          }, 0);

          const tamt = ((totalAmount / 50000000) * 100).toFixed(4);
          setTotalMht(tamt);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  return (
    <div className="presale video-background p position-relative" id="p">
      <video
        className="img-fluid video"
        data-v-27edef16=""
        data-test="video-content"
        preload="auto"
        src="./image/aff.mp4"
        playsInline
        loop
        muted
        autoPlay
        draggable="false"
      ></video>
      <div className="bg-blur"></div>
      <div
        className="row col-lg-12  position-relative justify-content-between"
        style={{ paddingTop: "80px" }}
      >
        <div className="col-lg-7 pt-5">{/* <Particle/> */}</div>
        <div
          className="row col-lg-5 text-white timer"
          style={{ marginBottom: "20px" }}
        >
          <div className="wallet-box glow-container g-box">
            <h6 className="text-center">
              Join the exclusive AMEYAA NXT Presale
            </h6>

            <div
              className="d-flex align-items-center justify-content-center w-100 gap-2 counter mb-3 "
              style={{ marginTop: "20px" }}
            >
              <div className="d-flex round">
                <div className="value">{timeLeft.days || "00"}</div>
                <div className="indicator">Days</div>
              </div>
              <div className="d-flex round">
                <div className="value">{timeLeft.hours || "00"}</div>
                <div className="indicator">Hours</div>
              </div>
              <div className="d-flex round">
                <div className="value">{timeLeft.minutes || "00"}</div>
                <div className="indicator">Minutes</div>
              </div>
              <div className="d-flex round">
                <div className="value">{timeLeft.seconds || "00"}</div>
                <div className="indicator">Seconds</div>
              </div>
            </div>

            <div className="progress mb-2">
              <div className="bar" style={{ width: totalMht + "%" }}></div>
              <div className="status">{totalMht + "%" ?? 0 + "%"}</div>
            </div>
            {/* <div
              className="d-flex justify-content-center mb-2"
              style={{ fontSize: "13px" }}
            >
              <span>USDT RAISED:</span>
              <span>$152,276.55 </span>
              <span>/ $817,977</span>
            </div> */}
            <div
              className="d-flex justify-content-center align-items-center text-center mb-3 font-12 px-3"
              style={{ fontSize: "13px" }}
            ></div>
            {/* <div className="position-relative my-4">
              <div className="text-center mb-3 font-14 dashTitle">
                1 Anxt = ${currentPrice}
              </div>

            </div> */}
            <div className="c-btn mb-4 list p-2">
              <div>
                <div className="d-flex justify-content-between">
                  <span
                    className="text-light"
                    style={{ fontSize: "16px", color: "white" }}
                  >
                    LISTING PRICE : $1.5
                  </span>
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-between">
                  <span className="text-light" style={{ fontSize: "16px" }}>
                    NEXT PRICE : $0.3
                  </span>
                </div>
              </div>
            </div>

            <div className="eth-usdt d-flex mb-3">
              <button className="btn b1">
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/usdt.svg"
                  alt=""
                />
                <span>USDT</span>
              </button>
              <button className="btn b2">
                <span>Balance</span>
                <span>{"$ " + userBalance?.toFixed(2)}</span>
              </button>
            </div>
            <div className="c-btn mb-4">
              <div>
                <div className="d-flex justify-content-between">
                  <span className="text-light">You Pay</span>
                </div>
                <div className="inp">
                  <input
                    type="text"
                    placeholder="You Pay"
                    // readOnly
                    value={pay}
                    onChange={(e) => {
                      setAmt(e.target.value / currentPrice);
                      setPay(e.target.value);
                    }}
                  />
                  <img
                    src="https://sale.starchain.dev/assets/images/svg-icons/usdt.svg"
                    alt=""
                    style={{ height: "28px" }}
                  />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <span className="text-light">You Get</span>
                  {/* <span>MAX</span> */}
                </div>
                <div className="inp">
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    onChange={(e) => {
                      setAmt(e.target.value);
                      setPay(e.target.value * currentPrice);
                    }}
                    value={amt}
                  />
                  <img
                    src="/image/amnLogo.png"
                    alt=""
                    style={{ height: "28px" }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <div className="d-flex justify-content-between">
                  <span className="text-light">Upline id</span>
                  {/* <span>MAX</span> */}
                </div>
                <div className="inp">
                  <input
                    type="text"
                    placeholder="Referral address"
                    onChange={(e) => {
                      setRefAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="connect mb-3">
              {/* <button className="btn btn1"> */}
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <div
                      {...(!ready && {
                        "aria-hidden": true,
                        style: {
                          opacity: 0,
                          pointerEvents: "none",
                          userSelect: "none",
                        },
                      })}
                      style={{ width: "100%" }}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <div
                              onClick={openConnectModal}
                              style={{ width: "100%" }}
                              className="btn btn1"
                            >
                              <span>Connect Wallet</span>
                            </div>
                          );
                        }

                        if (chain.unsupported) {
                          return (
                            <div onClick={openChainModal} className="btn btn1">
                              Wrong network
                            </div>
                          );
                        }

                        return (
                          <div
                            style={{ display: "flex", gap: 12 }}
                            className="btn btn1"
                            onClick={openAccountModal}
                          >
                            <div>{account.displayName}</div>
                          </div>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>{" "}
              {/* </button> */}
              <button
                className="btn d-flex justify-content-center align-item-center"
                style={{ gap: "5px" }}
                onClick={stake}
              >
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/usdt.svg"
                  alt=""
                  style={{ height: "18px" }}
                />
                <span>Buy With USDT</span>
              </button>
            </div>
            <div className="d-flex justify-content-between col-lg-12">
              <span className="text-light">Comming soon</span>
            </div>
            <div className="connect mb-3 mt-2">
              <button
                className="btn d-flex justify-content-center align-item-center"
                style={{ gap: "5px", cursor: "default" }}
              >
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  alt=""
                  style={{ height: "18px" }}
                />
                <span>BUY WITH ETH</span>
              </button>

              <button
                className="btn d-flex justify-content-center align-item-center"
                style={{ gap: "5px", cursor: "default" }}
              >
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                  alt=""
                  style={{ height: "18px" }}
                />
                <span>BUY WITH BNB</span>
              </button>
            </div>

            <div className="text-center">Unlock Period = 100 days</div>
          </div>
        </div>
      </div>

      {/* <div class="side-img">
        <img src="/image/robo.png" />
      </div> */}
    </div>
  );
}

export default Presale;
