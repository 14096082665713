import React from "react";

function Team() {
  return (
    <div className=" p mb-5 mt-5">
      <div className=" sol-title ms-3  ">Our Team</div>
      <div className="row col-lg-12 ">
        <div
          className=" col-sm  "
          data-aos="flip-left"
          data-aos-anchor-placement="top-center"
        >
          <div>
            <img className="img-fluid setH" src="/image/abhi.jpg" alt="" />
          </div>
          <div className="designation">
            <div className="name">CEO</div>
            <div className="des">Abhimanyu T</div>
          </div>
        </div>
        <div
          className=" col-sm  "
          data-aos="flip-left"
          data-aos-anchor-placement="top-center"
        >
          <div>
            <img
              className="img-fluid setH "
              style={{ maxHeight: "550px" }}
              src="/image/coFounder.jpeg"
              alt=""
            />
          </div>
          <div className="designation">
            <div className="name">Co Founder</div>
            <div className="des">Haruka Amayshita</div>
          </div>
        </div>
        <div
          className=" col-sm  "
          data-aos="flip-left"
          data-aos-anchor-placement="top-center"
        >
          <div>
            <img
              className="img-fluid setH"
              style={{ maxHeight: "550px" }}
              src="/image/cso.jpeg"
              alt=""
            />
          </div>
          <div className="designation">
            <div className="name">CSO </div>
            <div className="des">Nageshwaran Mahanathan </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
