import React from "react";

function System() {
  return (
    <div className="row col-lg-12 p">
      {/* <div className="ecosystem">Ecosystem</div> */}
      <div className="g-title">How Our Solutions Work</div>
      {/* <p className="g-p col-lg-8">
        By integrating advanced AI technology, Ameyaa NXT helps analyze market
        data, offering personalized recommendations to maximize your trading
        potential.By integrating advanced AI technology, Ameyaa NXT helps
        analyze market data, offering personalized recommendations to maximize
        your trading potential.
      </p> */}
      <div
        className="row col-lg-12 system"
        data-aos="fade-down-right"
        data-aos-anchor-placement="top-center"
      >
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">1</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              AI Integration
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              We will smoothly integrate our AI systems into your cryptocurrency
              platform to provide real-time fraud detection the moment you begin
              transacting.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">2</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              Continuous Learning
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              Our AI gets to learn with every transaction, making it even more
              efficient in identifying new fraud patterns.
            </p>
          </div>
        </div>{" "}
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">3</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              Fraud Neutralization
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              It flags fraudulent activities and neutralizes threats before they
              can affect your transactions to give you protection like no other.
            </p>
          </div>
        </div>
      </div>

      <a
        className="row col-lg-8  g-button"
        style={{ position: "relative", top: "50px" }}
        href="#p"
      >
        Join exclusive Presale Now
      </a>
    </div>
  );
}

export default System;
