import React from "react";

function Ecosystem() {
  return (
    <div
      className="p row col-lg-12 mt-5"
      style={{
        marginBottom: "180px",
      }}
      id="eco"
    >
      <div className="g-title mt-3">Why Ameyaa NXT?</div>
      <p className="sub-heading">Leaders in AI-Driven Fraud Prevention</p>

      <div className="row col-lg-12 eco">
        <div className="col-lg-4">
          <div>
            <p className="contributor">Innovative Technology</p>
            <p className="star">
              Our AI-driven systems are designed to keep at par with the curve,
              evolve, and adapt to the latest threats in cryptocurrency.
            </p>
            {/* <p className="apply">Apply now</p> */}
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            {/* <p className="become">Token Utilization </p> */}
            <p className="contributor">Real-Time Protection</p>
            <p className="star">
              We give 24/7 monitoring and real-time fraud prevention for the
              safety of your digital assets.
            </p>
            {/* <p className="apply">Apply now</p> */}
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            {/* <p className="become">Education</p> */}
            <p className="contributor">Tailor-made Solutions</p>
            <p className="star">
              We know that each firm is different; therefore we tailor our fraud
              prevention systems to suit your needs.
            </p>
            {/* <p className="apply">Apply now</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ecosystem;
