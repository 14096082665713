import React, { useEffect, useState } from "react";
import { getReward, getToken } from "./api";
import { useAccount } from "wagmi";

function Reward() {
  const { address } = useAccount();
  const [user, setUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getReward();
        if (res.status == 200) {
          console.log(res?.data?.data);
          setUser(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  return (
    <div className="row col-lg-8 m-auto table-responsive">
      <h3 className="text-white mt-5 r">Reward Details</h3>
      <table class="table  col-lg-12 mt-1 d-table">
        <thead>
          <tr>
            <th scope="col">Reward From</th>
            <th scope="col">Rewarded User</th>
            <th scope="col">Reward Amount</th>
          </tr>
        </thead>
        <tbody>
          {user?.length > 0 &&
            user?.map((it, index) => {
              console.log(it?.user, address, ":::");
              if (it?.RewardFrom == address) {
                return (
                  <tr key={index}>
                    <td scope="row">{it?.RewardFrom}</td>
                    <td>{it?.rewardedUser}</td>
                    <td>{(it?.rewardAmount / 1e18).toFixed(2)}</td>
                  </tr>
                );
              }
              return null; // Return null if the address doesn't match
            })}
        </tbody>
      </table>
    </div>
  );
}

export default Reward;
