import axios from "axios";
const api = "https://ameyaanxt.network/api";

export const getToken = async () => {
  const res = await axios.get(api + "/token");
  return res;
};

export const getReward = async () => {
  const res = await axios.get(api + "/reward");
  return res;
};
