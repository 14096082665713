import React from "react";
import Presale from "./page/Presale";
import Partner from "./page/Partner";
import Insider from "./page/Insider";
import Solution from "./page/Solution";
import Team from "./page/Team";
import Ecosystem from "./page/Ecosystem";
import System from "./page/System";
import Tokenomics from "./page/Tokenomics";
import Community from "./page/Community";
import Roadmap from "./page/Roadmap";
import FAQ from "./page/FAQ";
import Contact from "./page/Contact";
import Address from "./page/Address";

function AppRoutes() {
  return (
    <>
      <Presale />
      <Address/>
      <Partner />
      <Insider />
      {/* <Solution /> */}
      {/* <Team /> */}
      <Ecosystem/>
      <Roadmap/>
      <FAQ/>
      <System/>
      <Tokenomics/>
      <Contact/>
    </>
  );
}

export default AppRoutes;
