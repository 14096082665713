import React, { useEffect, useState } from "react";

function Solution() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
      document.documentElement.style.setProperty("--x", clientX);
      document.documentElement.style.setProperty("--y", clientY);
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="solution p  mb-3 mt-5" id="service">
      <div className="sol-title">Our Service</div>
      <p className="sub-heading">
        AI Powered Advanced Fraud Prevention Solution
      </p>
      <div
        className="row col-lg-12 "
        data-aos="fade-right"
        data-aos-anchor-placement="top-center"
      >
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                // src="/image/cube.svg"
                src="/image/ai1.jpg"
                alt=""
              />
            </div>
            <div className="data">AI-Driven Robotic Systems</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              Our intelligent systems use high-performance algorithms to
              dynamically detect fraudulent transactions in real-time, hence
              they are continuously learning about new threats. Your digital
              assets are safe.
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                src="/image/ai2.jpg"
                // src="/image/telescope.svg"
                alt=""
              />
            </div>
            <div className="data">Fraud Detection & Prevention</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              We specialize in safely encrypting cryptocurrency transactions
              with real-time fraud detection using AI and machine learning - to
              predict, identify, and neutralize all possible fraud before it
              happens
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                src="/image/ai3.jpg"
                // src="/image/trophy.svg"
                alt=""
              />
            </div>
            <div className="data">Secure Digital Transactions</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              Our solutions allow for secure cryptocurrency transactions. Ameyaa
              NXT ensures that all your financial transactions are secured
              through the highest level of security.
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solution;
