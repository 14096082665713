import React, { useEffect, useState } from "react";

function Insider() {
  return (
    <div className="insider p mx-2" id="about">
      <div
        className="col-lg-12 mt-5"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
      >
        <div className="insider-title">
          <h3 lassName="text-uppercase">
            Empowering Financial Security with AI-Driven Innovation
          </h3>
          {/* <span>
            {" "}
            <img
              src="https://sale.starchain.dev/assets/images/svg-icons/share.svg"
              alt=""
            />
          </span> */}
        </div>

        <p style={{ width: "100%" }} className="m-desc">
          Ameyaa NXT LLC - Leading the Future of Fraud Prevention in
          Cryptocurrency with Cutting-Edge Robotic Systems.
        </p>
        <p style={{ width: "100%" }} className="m-desc">
          Discover Our Solutions" or "Be a Part of Securing the Future in
          Cryptocurrency Transactions
        </p>

        <p className="m-desc">
          Ameyaa NXT harnesses the power of advanced artificial intelligence and
          robotics to ensure safe digital transactions and innovative fraud
          prevention. State-of-the-art systems that buttress the fluid and
          ever-changing cryptocurrency world with unmatched levels of security
          is something we pride ourselves on.
        </p>
      </div>
      <div
        className="col-lg-12 mt-5"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
      >
        <div className="insider-title">
          <h3 lassName="text-uppercase">Who We Are</h3>
          {/* <span>
            {" "}
            <img
              src="https://sale.starchain.dev/assets/images/svg-icons/share.svg"
              alt=""
            />
          </span> */}
        </div>

        <p className="m-desc">
          Ameyaa NXT LLC is the USA-based cryptocurrency company, at the
          forefront of innovation, building AI-powered robotic systems that
          prevent money fraud. We at Ameyaa NXT LLC focus on delivering
          cutting-edge, secure, and reliable technology solutions while ensuring
          our clients' protection from constantly evolving digital financial
          threats.
        </p>
        <p style={{ width: "100%" }} className="m-desc">
          To empower the businesses and individuals with the assurance of safe
          cryptocurrency transactions through our AI-driven solution
        </p>
        <p style={{ width: "100%" }} className="m-desc">
          Lead the world to a future with secure financial transactions and
          transparency in digital currency exchange.
        </p>
      </div>
    </div>
  );
}

export default Insider;
