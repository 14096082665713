import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { getToken } from "./api";
import moment from "moment";

function Token() {
  const { address } = useAccount();
  const [user, setUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getToken();
        if (res.status == 200) {
          console.log(res?.data?.data);
          const sortedData = res?.data?.data?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setUser(sortedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  return (
    <div className="row col-lg-8 m-auto table-responsive">
      <h3 className="text-white mt-5 r">Purchase Details</h3>
      <table
        class="table col-lg-12 mt-1 d-table"
        style={{ paddingLeft: "5px" }}
      >
        <thead>
          <tr>
            <th scope="col">Wallet Address</th>
            <th scope="col">Price</th>
            <th scope="col">USDT Paid</th>
            <th scope="col">Amount</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          {user?.length > 0 &&
            user?.map((it, index) => {
              console.log(it?.user?.toLowerCase(), address, ":::");
              if (
                it.user.toLowerCase() ==
                "0x6d9E9644b115C30865650F567a9D56E5E1E421Dc".toLowerCase()
              ) {
                return (
                  <tr key={index}>
                    <td scope="row">{it?.user}</td>
                    <td>{(it?.price / 1000).toFixed(2)}</td>
                    <td>{(it?.usdtPaid / 1e18).toFixed(2)}</td>
                    <td>{(it?.amount / 1e18)?.toFixed(2)}</td>
                    <td>{moment(it?.createdAt).format("DD-MM-YY")}</td>
                  </tr>
                );
              }
              return null; // Return null if the address doesn't match
            })}
        </tbody>
      </table>
    </div>
  );
}

export default Token;
