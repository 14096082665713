import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Roadmap = () => {
  return (
    <div
      className="row col-lg-12 mb-5  position-relative"
      style={{ marginLeft: "0px", marginTop: "0px" }}
    >


      <div className="mb-5 d-none d-lg-block d-md-block" style={{
        height:'fit-content',
        margin:'0px',
        padding:'0px',
        // marginTop:'20px'
      }}>
        <img
          src="/image/roadmapAi.svg"
          alt=""
          style={{
            height: "600px",
            position: "absolute",
            left: "-65px",
            top: "-310px",
            marginTop:'50px',
            marginBottom:'80px',
            // zIndex:'999'
          }}
        />
      </div>
      <div
        className="g-title d-flex"
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        ROADMAP
      </div>

      <VerticalTimeline className="mt-3 mb-3">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2023"
          iconStyle={{
            background: "rgb(33, 150, 243)",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon="Q3"
        >
          <h3 className="vertical-timeline-element-title" style={{color:"#f03c3c"}}>
            Research & Planning
          </h3>
          <p>
            Strategic planning now includes creating personalized investment
            strategies, utilizing real-time data analysis, and ensuring
            adherence to new regulations to thrive in a complex financial world.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2023"
          iconStyle={{
            background: "rgb(33, 150, 243)",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon="Q4"
        >
          <h3 className="vertical-timeline-element-title" style={{color:"#f03c3c"}}>
            Design & Prototyping{" "}
          </h3>

          <p>
            we rigorously evaluate the prototypes to ensure they meet
            performance standards, user requirements, and security protocols.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2024"
          iconStyle={{
            background: "rgb(33, 150, 243)",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon="Q1"
        >
          <h3 className="vertical-timeline-element-title" style={{color:"#f03c3c"}}>
            Blockchain & token integration
          </h3>

          <p>
            Our system utilizes blockchain technology to provide a
            decentralized, immutable ledger of all transactions, enhancing trust
            and reducing the risk of fraud.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2024"
          iconStyle={{
            background: "rgb(33, 150, 243)",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon="Q2"
        >
          <h3 className="vertical-timeline-element-title" style={{color:"#f03c3c"}}>
            TGE & Exchange & Crawlers Listings{" "}
          </h3>

          <p>
            This is the launch phase where Ameyaa NXT’s utility token is
            officially introduced to the market. During the TGE, tokens are
            distributed to early investors, stakeholders, and the public.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2024"
          iconStyle={{
            background: "rgb(233, 30, 99)",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon="Q3"
        >
          <h3 className="vertical-timeline-element-title" style={{color:"#f03c3c"}}>Testing & QA</h3>

          <p>
            Testing and QA (Quality Assurance) are critical phases in the
            development of Ameyaa NXT’s platform and its associated
            technologies.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Roadmap;
