import React, { useState } from "react";

function FAQ() {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const toggleQuestion = (index) => {
    if (openQuestionIndex === index) {
      setOpenQuestionIndex(null); // Close the currently open question if clicked again
    } else {
      setOpenQuestionIndex(index); // Open the clicked question
    }
  };

  // FAQ data
  const faqData = [
    {
      question: "What is AMEYAA NXT?",
      answer:
        "Ameyaa NXT LLC is a USA-registered cryptocurrency company that adheres to all regulatory guidelines and standards,ensuring compliance and security. Our core mission is to create an AI-driven robotic system designed to prevent money fraud, providing users with a safer, more reliable trading experience.",
    },
    {
      question: "How does AMEYAA NXT work?",
      answer:
        "Ameyaa NXT offers a powerful AI bot integrated directly into your phone to protect you from money fraud while optimizing your trading decisions",
    },
    // Add more questions and answers as needed
  ];

  return (
    <div
      className="row col-lg-12 p"
      style={{
        margin: "60px 0px",
      }}
      id="faq"
    >
      <div className="col-lg-5">
        <div>
          <div className="g-title">Frequently Asked Questions</div>
          <p className="g-p" style={{ fontSize: "20px" }}>
            Here you'll find answers to common questions about our platform, how
            it works, including token purchases, vesting, and the rewards
            processes.
          </p>
        </div>
      </div>
      <div className="col-lg-7">
        {faqData.map((faq, index) => (
          <div className="col-lg-12" key={index} style={{ margin: "20px 0px" }}>
            <div className="g-card">
              <div
                className="d-flex"
                style={{
                  gap: "20px",
                  margin: "10px 0px",
                  alignItems: "center",
                }}
                onClick={() => toggleQuestion(index)}
              >
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/plus.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <span
                  className="g-title"
                  style={{ fontSize: "22px", marginBottom: "0px" }}
                >
                  {faq.question}
                </span>
              </div>
              {openQuestionIndex === index && (
                <p
                  className="g-p"
                  style={{
                    fontSize: "18px",
                    marginTop: "20px",
                    paddingLeft: "40px",
                    color: "#b0b0b0",
                  }}
                >
                  {faq.answer}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
